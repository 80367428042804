.distributions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 7rem 0;
  padding-top: 4rem;

  h1 {
    font-weight: 700;
    font-size: 35px;

    letter-spacing: 1.5px;
    font-family: 'Alfa Slab One';
    text-shadow: 1px 1px 1px #030303;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5rem;
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }

    /* @media screen and (max-width: 1200px) {
      line-height: 60px;
    }

    @media screen and (max-width: 930px) {
    }

    @media screen and (max-width: 700px) {
      line-height: 40px;
    }

    @media screen and (max-width: 400px) {
    } */
  }

  img {
    width: 80%;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
}

.funding_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  img {
    width: 30%;
    margin: 0rem 1rem;
    @media screen and (max-width: 1050px) {
      width: 20%;
    }
    @media screen and (max-width: 900px) {
      width: 30%;
    }
    @media screen and (max-width: 400px) {
      width: 50%;
    }
  }
  .funding {
    color: white;
    width: 50%;
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 1rem 3rem;
    h2 {
      margin-bottom: 1.5rem;
      @media screen and (max-width: 1050px) {
        font-size: 20px;
      }
      @media screen and (max-width: 900px) {
        font-size: 30px;
      }
      @media screen and (max-width: 550px) {
        font-size: 20px;
      }
    }
    p {
      margin-bottom: 3rem;
      @media screen and (max-width: 1050px) {
        font-size: 12px;
      }
      @media screen and (max-width: 900px) {
        font-size: 20px;
      }
      @media screen and (max-width: 550px) {
        font-size: 12px;
      }
    }
  }
}
