.media {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0;
  padding-top: 7rem;

  h1 {
    font-weight: 700;
    font-size: 35px;

    letter-spacing: 2.5px;
    font-family: 'Alfa Slab One';
    text-shadow: 1px 1px 1px #030303;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3rem;
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }

    /* @media screen and (max-width: 1200px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (max-width: 930px) {
      font-size: 40px;
    }

    @media screen and (max-width: 700px) {
      font-size: 30px;
      line-height: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 23px;
    } */
  }

  img {
    width: 80%;
    margin-top: 2rem;
    padding: 0 3rem;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
}
