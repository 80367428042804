.connect_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .border {
    border-color: #ffffff;
    border-style: dotted;
    border-radius: 15px;
    background: #5e5e77;
    padding: 0rem 3rem;

    p {
      color: red;
      padding: 0.5rem;
      font-family: "Alfa Slab One", cursive;
      font-size: 25px;
      font-weight: 400;
      text-shadow: 2px 2px 2px #030303;
      text-align: center;
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }

  button {
    background-color: rgb(255, 255, 255);
    color: #9797c9;
    font-size: 20px;
    line-height: 30px;
    border-radius: 11px;
    font-family: "Alfa Slab One", cursive;
    padding: 0.5rem 1.5rem;
    font-weight: 100;
    filter: brightness(100%);
    cursor: pointer;
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #6868b1;
    transition: ease-in-out 0.2s;

    &:hover {
      background-color: #a7a7f7;
      color: #ffffff;
    }
    &:active {
      background-color: #ffffff;
      color: #a7a7f7;
      box-shadow: 0 5px #000000;
      transform: translateY(4px);
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      //margin-top: -2px;
      color: #ffffff;
      //margin-left: 10px;
      text-shadow: 2px 2px 2px #6868b1;
      @media screen and (max-width: 700px) {
        font-size: 15px;
        width: 100%;
        line-height: 20px;
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}
.minting_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    font-size: 40px;
    margin-bottom: 2rem;
    font-family: "Alfa Slab One", cursive;
    letter-spacing: 3px;
    text-shadow: 2px 2px 2px #030303;
    text-align: center;
    @media screen and (max-width: 450px) {
      font-size: 25px;
      margin-bottom: 1rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: "Alfa Slab One", cursive;
      text-shadow: 2px 2px 2px #030303;
    }
  }
  .border {
    border-color: #ffffff;
    border-style: dotted;
    border-radius: 15px;
    background: #5e5e77;
    padding: 0rem 3rem;

    p {
      color: red;
      padding: 0.5rem;
      font-family: "Alfa Slab One", cursive;
      font-size: 25px;
      font-weight: 400;
      text-shadow: 2px 2px 2px #030303;
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.big_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  .timer_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 1rem;
      width: 5rem;
      background: #ffffff;
      margin: 1rem 0.5rem;
      border: #030303 3px solid;
      border-radius: 15px;
      font-family: "Alfa Slab One", cursive;
      font-size: 13px;
      @media screen and (max-width: 700px) {
        width: 4rem;
        font-size: 10px;
      }
      @media screen and (max-width: 450px) {
        width: 3rem;
        height: 3.5rem;
        font-size: 7px;
      }
    }
  }
}

.increase {
  width: 50px;
  height: 50px;
  font-size: 33px;
  background: #a7a7f7;
  border-radius: 50px;
  font-weight: 800px;
  box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 4px;
  cursor: pointer;
  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
  &:active {
    background-color: #a7a7f7;
    box-shadow: 0 5px #000000;
    transform: translateY(4px);
  }
}

.p-text {
  width: 100px;
  border: #000000 2px solid;
  border-radius: 15px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    width: 80px;
    font-size: 20px;
  }
}

.mint_button {
  color: #ffffff;
  font-size: 25px;
  background: #a7a7f7;
  font-family: "Alfa Slab One", cursive;
  padding: 0.5rem 3.5rem;
  margin-top: 1rem;
  border-radius: 15px;
  letter-spacing: 1px;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 4px;

  @media screen and (max-width: 450px) {
    padding: 0.5rem 2.5rem;
    font-size: 20px;
  }
  &:hover {
    background-color: #ffffff;
    color: #a7a7f7;
  }
  &:active {
    background-color: #a7a7f7;
    color: #ffffff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
}

.price {
  font-size: 20px !important;
  margin-top: 2rem;
  @media screen and (max-width: 450px) {
    margin-top: 1rem;
  }
}

.white {
  color: #ffffff;
  text-decoration: underline;
  font-family: "Alfa Slab One", cursive;
  letter-spacing: 3px;
  text-shadow: 2px 2px 2px #030303;

  @media screen and (max-width: 550px) {
    font-size: 25px;
  }
}
