.footer {
  background-color: #4a3d87;
  height: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    margin-top: -4rem;
    @media screen and (max-width: 500px) {
      margin-top: -4rem;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1rem;

      a {
        .big {
          max-width: 200px;
          height: auto;

          @media screen and (max-width: 500px) {
            max-width: 150px;
          }
        }

        img {
          margin: 0 0.5rem;
          max-width: 35px;
          height: auto;
          cursor: pointer;

          &:hover {
            filter: brightness(1.2);
          }
          @media screen and (max-width: 500px) {
            max-width: 25px;
          }
        }
      }
    }

    p {
      color: #ffffff;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 18px;
      margin: 0.5rem 0rem;

      @media screen and (max-width: 500px) {
        margin-top: 0.5rem;
        font-size: 12px;
      }
    }
  }
}

.footer_info {
  margin-top: -4.8rem;
  text-align: center;
  @media screen and (max-width: 500px) {
    margin-top: -3rem;
  }
}

.spec {
  font-weight: 800;
  text-decoration: underline solid;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
}
