:root {
  --word-color: #ffffff;
  --team-title-color: #a7a7f7;
}

:root::-webkit-scrollbar {
  width: 10px;
  background: rgba(173, 167, 167, 0.2);
}

:root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

:root::-webkit-scrollbar-thumb {
  background-color: rgb(117, 117, 117);
  outline: 1px solid slategrey;
  border-radius: 25px;
}
