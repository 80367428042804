.genesis-container {
  display: flex;
  flex-direction: column;

  padding-top: 5rem;
  margin-bottom: 1rem;
}

.genesis {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  img {
    width: 350px;
    @media screen and (max-width: 650px) {
      width: 220px;
    }
  }
}

.genesis-word {
  color: #fff;
  text-shadow: 1px 1px 1px #666565;

  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 2rem;
    letter-spacing: 2.5px;
    font-size: 35px;
    text-shadow: 1px 1px 1px #000000;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }
  }
  p {
    padding-right: 12rem;
    letter-spacing: 1.5px;
    font-size: 16px;
    text-align: justify;
    margin-bottom: 1rem;
    @media screen and (max-width: 900px) {
      text-align: center;
      padding-right: 0;
      text-align: center;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }
}

.carousell {
  width: 100%;
}
