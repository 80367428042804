.vertical-timeline-element-title {
  font-family: 'Alfa Slab One';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
  color: #6868b1;
  text-align: left;

  text-shadow: 3px 2px 0px #ffffff;
}

.vertical-timeline::before {
  width: 10px !important;
  top: 90px !important;
  height: 68% !important;
}

.roadmap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //width: 90%;
  margin: 0;
  padding-top: 7rem;

  .header {
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 2rem;
    letter-spacing: 2.5px;
    font-family: 'Alfa Slab One';
    text-shadow: 1px 1px 1px #030303;
    color: white;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }

    /* @media screen and (max-width: 1200px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (max-width: 930px) {
      font-size: 40px;
    }

    @media screen and (max-width: 700px) {
      font-size: 30px;
      line-height: 40px;
    }

    @media screen and (max-width: 400px) {
      font-size: 23px;
    } */
  }

  .text {
    font-family: 'Alfa Slab One';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-shadow: 1px 1px 1px #666565;

    color: white;
    text-align: justify;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50%;

    @media screen and (max-width: 1200px) {
      //font-size: 14px;
      margin-top: 1rem;
      width: 80%;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
    }

    @media screen and (max-width: 930px) {
      font-size: 16px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    img {
      max-width: 216px;
      max-height: 350px;
      margin-top: 100px;
      margin-left: 20px;
      //margin-right: 50px;
      width: 30%;
      @media screen and (max-width: 1000px) {
        width: 50%;
        margin-left: 0px;
      }

      &:last-child {
        max-width: 376px;
        max-height: 442px;
        align-self: flex-end !important;
        margin-bottom: 400px;
        margin-right: 20px;
        width: 30%;
        @media screen and (max-width: 1000px) {
          align-self: unset !important;
          margin-top: 0px;
          margin-bottom: 0px;
          width: 50%;
          margin-right: 0px;
        }
      }
    }

    .timeline {
      width: 40%;

      @media screen and (max-width: 1000px) {
        width: 80%;
      }

      .items {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0px;
        }

        h1 {
          width: 50%;
          margin: 0;
          display: unset;
          font-size: 35px;

          @media screen and (max-width: 650px) {
            font-size: 30px;
          }

          /* @media screen and (max-width: 1200px) {
            font-size: 40px;
            //line-height: 60px;
          }

          @media screen and (max-width: 930px) {
            font-size: 30px;
          }

          @media screen and (max-width: 700px) {
            font-size: 30px;
            line-height: 40px;
          }

          @media screen and (max-width: 400px) {
            font-size: 23px;
          } */
        }

        p {
          font-family: 'Alfa Slab One';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 1.5px;
          text-align: left;
          text-shadow: 1px 1px 1px #666565;

          @media screen and (max-width: 1200px) {
            font-size: 16px;
            //margin-top: 1rem;
          }

          @media screen and (max-width: 930px) {
            font-size: 16px;
            //margin-top: 1rem;
          }
          @media screen and (max-width: 650px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
