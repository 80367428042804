.Benifits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  color: #fff;
  text-shadow: 0.5px 0.5px 0px #000000;

  h1 {
    padding: 20px;
    text-align: center;
    overflow-wrap: break-word;
    inline-size: 450px;
    letter-spacing: 2.5px;
    margin-left: 3rem;
    margin-right: 3rem;
    text-shadow: 1px 1px 1px #030303;
    @media screen and (max-width: 500px) {
      font-size: 30px;
      inline-size: 350px;

      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  p {
    margin-top: 1rem;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 3rem;
    text-align: justify;
    letter-spacing: 1.5px;
    margin-left: 3rem;
    margin-right: 3rem;
    @media screen and (max-width: 900px) {
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
    }
    @media screen and (max-width: 850px) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: center;
    }
  }
  img {
    margin-top: 1rem;
    width: 95%;
  }
}
