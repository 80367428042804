@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
.Big {
  position: sticky;
  top: 0;

  z-index: 9999;
  padding-bottom: 5.5rem;
  width: 100%;
  background-color: rgba(74, 61, 135, 0.95);
  display: flex;
  justify-content: center;
}

.BV__navbar {
  position: absolute;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 92px;
  padding-bottom: 10px;
  padding: 0rem 0.5rem;
  align-items: center;

  z-index: 9999;
  max-width: 1440px;
  
  
}
.BV__navbar-links {
  flex: 1;
  display: flex;
  height: 120px;

  padding-inline: 3%;
}

.BV__navbar-links_logo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  height: 100%;
  padding-top: 3px;
  margin-left: -1rem;
  @media screen and (max-width: 580px) {
    margin-left: -1rem !important;
  }
  @media screen and (max-width: 380px) {
    margin-left: 0.5rem !important;
  }
}

.BV__navbar-links_logo img {
  height: 200px;
  margin-right: 5rem;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    filter: brightness(1.2);
  }
  @media screen and (max-width: 1050px) {
    height: 140px;
  }
  @media screen and (max-width: 500px) {
    height: 100px;
  }
  
}

.BV__navbar-links_container {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: space-around;
  margin-left: 15%;
  width: 60%;
  height: 35px;
  font-size: 22px;
  position: absolute;
  @media screen and (max-width: 700px) {
    margin-left: 22%;
  }
  @media screen and (max-width: 500px) {
    margin-left: 16%;
  }

  p {
    padding: 0.7rem;
    font-family: 'Alfa Slab One', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    width: max-content;
    padding: 5px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
    a {
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #a7a7f7;
        filter: brightness(120%);
      }
    }
  }
}

.BV__navbar-connect {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.BV__navbar-links_container p,
.BV__navbar-connect p,
.BV__navbar-menu_container p {
  color: #fff;
}
.BV__navbar-connect {
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
.BV__navbar-connect button {
  background-color: rgb(255, 255, 255);
  color: #9797c9;
  font-size: 20px;
  line-height: 30px;
  border-radius: 11px;
  font-family: 'Alfa Slab One', cursive;
  padding: 0.5rem 1.5rem;
  font-weight: 100;
  filter: brightness(100%);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6868b1;
  transition: ease-in-out 0.2s;
  @media screen and (max-width: 600px) {
    width: 120px;

    height: 50px;
  }
  &:hover {
    background-color: #a7a7f7;
    color: #ffffff;
  }
  &:active {
    background-color: #ffffff;
    color: #a7a7f7;
    box-shadow: 0 5px #000000;
    transform: translateY(4px);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin-top: -2px;
    color: #ffffff;
    margin-left: 10px;
    text-shadow: 2px 2px 2px #6868b1;
    @media screen and (max-width: 600px) {
      font-size: 10px;
      width: 100%;
      line-height: 20px;
      text-align: center;
      margin-left: 0px;
    }
  }
}

.BV__navbar-connect button:hover {
  filter: brightness(1.2);
}
.BV__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.BV__navbar-menu svg {
  cursor: pointer;
}

.BV__navbar-menu_BIG {
  align-items: flex-start;
  width: 100%;
  z-index: 2000;
}
.BV__navbar-menu_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  background: #4a3d87;
  padding: 3rem;
  position: absolute;
  top: 80px;

  width: 100%;

  border-radius: 20px;

  border-top: 1px solid #112d3d;
  z-index: 1;
  transition: width 1s, height 1s;
}

.BV__navbar-menu_container div {
  display: block;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: bold;
  line-height: 54px;
  border-bottom: 1px solid #ffffff;
  box-shadow: inset 0 0 0 0 gray;
  width: 100%;
  transition: ease-in-out 0.4s;
  border-radius: 20px;

  a {
    font-weight: 400;
    font-family: 'Alfa Slab One', cursive;
    color: #ffffff;
  }
}
.BV__navbar-menu_container div:first-child {
  padding-top: 3%;
}
.BV__navbar-menu_container div:hover {
  border-radius: 20px;
  text-indent: 5%;
  a {
    filter: brightness(130%);
  }
  /*  text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
    1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
    1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
    1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);*/
}

.social_container {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  margin-left: -1rem;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  img {
    padding-right: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    filter: brightness(90%);
    width: 40px;

    @media screen and (max-width: 600px) {
      width: 40px;
    }
    @media screen and (max-width: 420px) {
      width: 32px;
    }
    &:hover {
      filter: brightness(120%);
    }
  }
}

.mask {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  justify-content: center;
  z-index: 100;
  position: absolute;
  overflow: hidden;
}
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 0.5s ease;
}

.menu-primary-enter-exit {
  position: absolute;
}

.social-menu {
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100% !important;
  border-bottom: #fff !important;
  @media screen and (max-width: 1150px) {
    display: none !important;
  }
  @media screen and (max-width: 380px) {
    display: flex !important;
  }
  img {
    width: 40px;
  }
}

@media screen and (max-width: 1150px) {
  .BV__navbar-links_container {
    p {
      display: none;
    }
    div {
      width: 100%;
      justify-content: flex-start !important;
      margin-left: 2rem;
      @media screen and (max-width: 380px) {
        display: none !important;
      }
    }
  }
  .BV__navbar-menu {
    display: flex;
  }
}

.land {
  color: #eeff02 !important;
  transition: all 0.2s ease;
  &:hover {
    color: #ffc400 !important;
    filter: brightness(120%);
  }
}
