.background_section_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
  margin-left: 3rem;
  @media screen and (max-width: 500px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.background_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: justify;
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    text-align: center;
  }
  img {
    height: 500px;

    @media screen and (max-width: 1100px) {
      height: 400px;
    }
    @media screen and (max-width: 500px) {
      height: 300px;
    }
  }
}
.background {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;

  color: #fff;
  text-shadow: 1px 1px 1px #666565;
  letter-spacing: 5%;
  @media screen and (max-width: 900px) {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 500px) {
    padding-right: 0px;
    margin-left: 0px;
  }
  h1 {
    letter-spacing: 2.5px;
    text-shadow: 1px 1px 1px #030303;

    @media screen and (max-width: 650px) {
      font-size: 30px;
    }
  }
  p {
    margin-top: 20px;
    letter-spacing: 1.5px;
    padding-right: 2rem;
    max-height: max-content;
    line-height: auto;
    @media screen and (max-width: 900px) {
      padding-right: 0;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      padding-right: 0px;
    }
  }
}

.vision {
  display: flex;
  flex-direction: column;

  padding-left: 100px;
  text-align: justify;
  color: #fff;
  text-shadow: 0.5px 0.5px 0px #000000;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    padding-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    padding-left: 0px;
  }
  h1 {
    letter-spacing: 2.5px;
    text-shadow: 1px 1px 1px #030303;

    @media screen and (max-width: 650px) {
      font-size: 30px;
    }
  }
  p {
    letter-spacing: 1.5px;
    margin-top: 20px;
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
}

.vision_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;
  margin-top: 2rem;
  @media screen and (max-width: 1100px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding-left: 0 !important;
  }
  img {
    margin-top: -50px;
    height: 400px;
    animation-duration: 5s;
    @media screen and (max-width: 1100px) {
      height: 400px;
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 500px) {
      height: 300px;
    }
  }
}
