.Team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;
  padding-bottom: 20rem;
  margin: 0 10rem;

  @media screen and (max-width: 1200px) {
    margin: 0 5rem;
  }

  @media screen and (max-width: 600px) {
    margin: 0 3rem;
    padding-top: 5rem;
    padding-bottom: 10rem;
  }

  h1 {
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    letter-spacing: 2.5px;
    font-family: 'Alfa Slab One';
    text-shadow: 1px 1px 1px #030303;
    color: white;
    margin-bottom: 2rem;
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }

    /* @media screen and (max-width: 1200px) {
      font-size: 50px;
    }

    @media screen and (max-width: 930px) {
      font-size: 40px;
    }

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 23px;
    } */
  }

  p {
    font-family: 'Alfa Slab One';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-shadow: 1px 1px 1px #666565;
    color: white;
    text-align: justify;
    margin-top: 3rem;

    @media screen and (max-width: 1200px) {
      //font-size: 14px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
    }

    @media screen and (max-width: 930px) {
      font-size: 16px;
      margin-top: 0rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }

  .member {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 2rem;
    align-items: flex-start;
    @media screen and (max-width: 900px) {
      justify-content: space-around;
    }
    @media screen and (max-width: 650px) {
      justify-content: space-between;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 1.5rem;

      @media screen and (max-width: 900px) {
        margin: 0.5rem 0.5rem;
        width: 40%;
      }

      h4 {
        margin-top: 1.5rem;

        font-family: 'Alfa Slab One';
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.08em;
        color: #a7a7f7;
        text-shadow: 2px 2px 0px #000000;
        text-align: center;
        @media screen and (max-width: 900px) {
          font-size: 90%;
        }
        @media screen and (max-width: 650px) {
          font-size: 80%;
        }

        &:nth-child(3) {
          margin-top: 0;
          color: white;
        }
      }

      p {
        inline-size: 300px;
        text-align: center;
        margin-top: 1rem;
        @media screen and (max-width: 900px) {
          font-size: 70%;
          inline-size: 200px;
        }
        @media screen and (max-width: 650px) {
          font-size: 60%;
          inline-size: 140px;
        }
      }

      img {
        margin-top: 1rem;
      }
      a {
        img {
          @media screen and (max-width: 900px) {
            width: 80%;
            height: 80%;
          }
          @media screen and (max-width: 600px) {
            width: 70%;
            height: 70%;
          }
          cursor: pointer;
          &:hover {
            filter: brightness(1.2);
          }
        }
      }
    }
  }
}

.pic {
  @media screen and (max-width: 900px) {
    width: 70%;
  }
}
