.hero {
  width: 100%;
  height: 100%;
  margin-top: 3rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
