.story {
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding-top: 10rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 1200px) {
    padding-bottom: 0rem;
  }

  @media screen and (max-width: 600px) {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }

  h1 {
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 2rem;

    font-family: 'Alfa Slab One', cursive;
    letter-spacing: 2px;
    text-shadow: 1px 1px 1px #030303;

    color: white;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 23px;
    }
  }

  p {
    font-family: 'Alfa Slab One';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-shadow: 1px 1px 1px #666565;
    color: white;
    text-align: justify;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 1200px) {
      //font-size: 14px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
    }

    @media screen and (max-width: 930px) {
      font-size: 16px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }

  img {
    width: 95%;
    margin-top: 3rem;
    align-self: center;
  }
}

.text {
  margin-left: 5rem;
  margin-right: 5rem;
  @media screen and (max-width: 500px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
