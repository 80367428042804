.Background {
  background: #238ebc;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;
}

.weland_mint_section {
  max-width: 1440px;
  margin: auto;
  padding-top: 2rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pipl_pictures {
    max-height: 500px;
    min-height: 500px;
    overflow: overlay;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      background-color: #238ebc;
      border-radius: 25px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #466f81;
      border-radius: 20px;
    }
    .picture {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 550px) {
        width: 50%;
      }
      img {
        width: 70%;
        border-radius: 10px;
        margin: 0.5rem 0rem;
        @media screen and (max-width: 550px) {
          width: 50%;
        }
      }
      .selected {
        border: 10px solid rgb(245, 229, 137);
      }
      .approve {
        margin-bottom: 1.5rem;
      }
      button {
        width: 70%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin: 0.5rem 0rem;
        color: white;
        background: #a5a5f7;
        border-radius: 10px;
        font-family: 'Alfa Slab One', cursive;
        font-weight: 400;
        font-size: 17px;
        cursor: pointer;
        transition: all 0.1s ease;
        box-shadow: 2px 3px rgb(7, 7, 7);
        @media screen and (max-width: 750px) {
          font-size: 12px;
        }
        @media screen and (max-width: 550px) {
          width: 50%;
        }

        &:hover {
          color: #a5a5f7;
          background: white;
        }
        &:active {
          box-shadow: 0 5px rgb(7, 7, 7);
          transform: translateY(4px);
        }
      }
    }
  }
}
.mint_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #015a96;
  width: 100%;

  h3 {
    margin: 0rem 1rem;
    text-align: center;
    color: white;
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    @media screen and (max-width: 750px) {
      font-size: 12px;
    }
    @media screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
}
.approves {
  width: 300px;
  padding: 0.5rem 0.5rem;

  margin: 0.5rem 1rem;
  color: white;
  background: #a5a5f7;
  border-radius: 10px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 2px 3px rgb(7, 7, 7);
  @media screen and (max-width: 750px) {
    font-size: 12px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
  &:hover {
    color: #a5a5f7;
    background: white;
  }
  &:active {
    box-shadow: 0 5px rgb(7, 7, 7);
    transform: translateY(4px);
  }
}

.disa {
  color: white !important;
  background: grey !important;
  &:active {
    box-shadow: 2px 3px rgb(7, 7, 7) !important;
    transform: translateY(0px) !important;
  }
}

.get {
  width: 200px;
  padding: 0.5rem 0.5rem;

  margin: 0.5rem 1rem;
  color: white;
  background: #a5a5f7;
  border-radius: 10px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 2px 3px rgb(7, 7, 7);
  @media screen and (max-width: 750px) {
    font-size: 12px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
  &:hover {
    color: #a5a5f7;
    background: white;
  }
  &:active {
    box-shadow: 0 5px rgb(7, 7, 7);
    transform: translateY(4px);
  }
}

.connectWallet {
  height: 500px;
  button {
    width: 300px;
    padding: 0.5rem 0.5rem;

    margin: 0.5rem 1rem;
    color: white;
    background: #a5a5f7;
    border-radius: 10px;
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.1s ease;
    box-shadow: 2px 3px rgb(7, 7, 7);
    @media screen and (max-width: 750px) {
      font-size: 12px;
    }
    &:hover {
      color: #a5a5f7;
      background: white;
    }
    &:active {
      box-shadow: 0 5px rgb(7, 7, 7);
      transform: translateY(4px);
    }
  }
}

.notenough {
  color: red !important;
}

.unselect {
  color: #a5a5f7 !important;
  background: white !important;
}

.disab {
  background: grey;
  width: 200px;
  padding: 0.5rem 0.5rem;

  margin: 0.5rem 1rem;
  color: white;

  border-radius: 10px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 2px 3px rgb(7, 7, 7);
  &:active {
    box-shadow: 2px 3px rgb(7, 7, 7) !important;
    transform: translateY(0px) !important;
  }
  @media screen and (max-width: 750px) {
    font-size: 12px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
    width: 220px;
  }

  &:hover {
    color: white;
    background: grey;
  }
  &:active {
    box-shadow: 0 5px rgb(7, 7, 7);
    transform: translateY(4px);
  }
}

.dis {
  background: grey !important;
  &:active {
    box-shadow: 2px 3px rgb(7, 7, 7) !important;
    transform: translateY(0px) !important;
  }
  &:hover {
    color: white !important;
    background: grey;
  }
}

.bigland {
  width: 100%;
}

.intro {
  display: flex;
  flex-direction: row;
  padding-bottom: 3rem;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }

  img {
    width: 40%;
    margin-left: 2rem;
    margin-right: 2rem;
    @media screen and (max-width: 750px) {
      width: 50%;
    }
    @media screen and (max-width: 470px) {
      width: 90%;
      margin-bottom: 2rem;
    }
  }
}

.word {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  margin-right: 3rem;
  @media screen and (max-width: 750px) {
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    margin-right: 0rem;
    
  }
  h1 {
    letter-spacing: 3px;
    margin-bottom: 1rem;
    @media screen and (max-width: 1150px) {
      font-size: 25px;
    }
    @media screen and (max-width: 950px) {
      font-size: 20px;
    }
    @media screen and (max-width: 470px) {
      font-size: 15px;
    }
  }
  p {
    letter-spacing: 1px;
    margin-right: 2rem;
    @media screen and (max-width: 1150px) {
      font-size: 12px;
    }
    @media screen and (max-width: 950px) {
      font-size: 10px;
    }
    @media screen and (max-width: 750px) {
      margin-right: 0rem;
    }
    @media screen and (max-width: 600px) {
      margin: 0rem 1.5rem;
    }
    @media screen and (max-width: 470px) {
      font-size: 9px;
    }
  }
}
.wearelandcontainer{
display: flex;
  margin-left: -2rem;
  margin-bottom: 0.5rem;
  justify-content:left;
  align-items: center;
    @media screen and (max-width: 750px) {
        justify-content: center !important;
          align-items: center !important;
        margin-left: 5rem !important;
      }
            @media screen and (max-width: 470px) {
              justify-content: center !important;
              align-items: center !important;
              margin-left: 3rem !important;
            }
  
}


.wearepipllogo {


  

  @media screen and (max-width: 470px) {
      width: 60% !important;
    }
 
}

.landopensea{

  margin-left: -1rem!important;
width: 23% !important;
  @media screen and (max-width: 750px) {
    width: 35% !important;
  margin-left: -5rem !important;
  }
        @media screen and (max-width: 470px) {
          width: 45% !important;
          margin-left: -3rem !important;
        }
}


.sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}

.loading_section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: linear-gradient(
    120deg,
    hsl(39, 100%, 51%),
    hsl(64, 100%, 50%),
    hsl(101, 100%, 50%)
  );
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.big_sec {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #015a96;
  margin: auto;
  padding: 1rem 3rem;
  justify-content: center;
  align-items: center;
}

.points {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: #fff;
  max-width: 1440px;
  margin-bottom: 3rem;
  text-align: left;
  ol {
    list-style-type: disc;
  }
  h2 {
    letter-spacing: 2px;
    margin-bottom: 0.5rem;
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    @media screen and (max-width: 1150px) {
      font-size: 25px;
    }
    @media screen and (max-width: 950px) {
      font-size: 20px;
    }
    @media screen and (max-width: 470px) {
      font-size: 15px;
    }
  }
  li {
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0.4rem;

    @media screen and (max-width: 1150px) {
      font-size: 12px;
    }
    @media screen and (max-width: 950px) {
      font-size: 10px;
    }
    @media screen and (max-width: 470px) {
      font-size: 9px;
    }
  }
}

.youdont {
  max-height: 490px;
  margin: auto;
  width: 40%;
  padding-bottom: 1rem;
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 700px) {
    width: 70%;
  }
  @media screen and (max-width: 450px) {
    width: 85%;
  }
}

.rules {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4rem;
  margin-left: -2rem;

  @media screen and (max-width: 380px) {
    margin-left: -2rem;
  }
  h1 {
    @media screen and (max-width: 470px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 380px) {
      font-size: 18px !important;
    }
  }
  @media screen and (max-width: 750px) {
    margin-left: 0rem;
  }

  .rule {
    display: flex;
    flex-direction: column;

    img {
      width: 150px;
      @media screen and (max-width: 1000px) {
        width: 120px;
      }
      @media screen and (max-width: 560px) {
        width: 80px;
      }
      @media screen and (max-width: 425px) {
        width: 60px;
      }
      @media screen and (max-width: 380px) {
        width: 55px;
      }
    }

    p {
      text-align: center;
      color: #faff00;
      @media screen and (max-width: 380px) {
        font-size: 8px !important;
      }
    }
    .rule_1 {
      padding: 1rem 1rem;
      @media screen and (max-width: 470px) {
        padding: 0rem 0.5rem;
      }
    }
    .rule_2 {
      padding: 1rem 1rem;
      margin-top: 1rem;
      @media screen and (max-width: 470px) {
        padding: 0rem 0.5rem;
        margin-top: 0rem;
      }
    }
    .blkland {
      margin-top: -2rem;

      @media screen and (max-width: 470px) {
        margin-top: 0rem;
      }
      @media screen and (max-width: 425px) {
        width: 80px;
      }
      @media screen and (max-width: 380px) {
        width: 70px;
      }
    }
  }
}
@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.page_selector {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding: 1rem 0rem;
  margin-bottom: 2rem;
  justify-content: space-around;
  --border-width: 3px;
  --angle: 0deg;
  border-radius: 10px;
  border-bottom: 7px solid transparent;

  border-image: repeating-linear-gradient(
      var(--angle),
      hsl(340, 91%, 65%),
      hsl(269, 85%, 66%),
      hsl(224, 85%, 66%),
      hsl(172, 100%, 50%)
    )
    1;

  border-image-slice: 1;
  animation: 5s rotate ease-in-out infinite;
  @include keyframes(rotate) {
    0% {
      --angle: 30deg;
    }
    50% {
      --angle: 172deg;
    }
    100% {
      --angle: 30deg;
    }
  }

  @media screen and (max-width: 650px) {
    padding: 0.5rem 0rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 2rem;
  }
  h2 {
    color: white;

    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    font-size: 30px;
    cursor: pointer;
    padding-bottom: 0.5rem;
    transition: all 0.1s ease;
    @media screen and (max-width: 900px) {
      font-size: 25px;
    }
    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
    &:hover {
      color: rgb(255, 238, 7);
    }
  }
  .activepage {
    color: rgb(255, 238, 7);
  }
}
.freeze {
  width: 200px !important;
  border-radius: 10px !important;

  @media screen and (max-width: 800px) {
    width: 150px !important;
  }
  @media screen and (max-width: 650px) {
    width: 120px !important;
  }
}

.ff-canvas-ready {
  transform: scale(0.95);
  border-radius: 10px !important;
  margin: 0.5rem 0rem;
}

.id {
  color: white;

  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  font-size: 20px;
  margin-top: 1.5rem;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.howToClaim {
  text-align: center;
  color: white;

  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 1rem 1rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 420px) {
    margin-bottom: 2rem;
    margin-top: -1rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  h2 {
    @media screen and (max-width: 1150px) {
      font-size: 20px;
    }
    @media screen and (max-width: 420px) {
      font-size: 16px;
      letter-spacing: 3px;
    }
  }
  p {
    margin: 0.5rem 0rem;
    @media screen and (max-width: 1150px) {
      font-size: 12px;
    }
    @media screen and (max-width: 420px) {
      font-size: 10px;
    }
  }
}

.pointPic {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 2rem;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  img {
    @media screen and (max-width: 1150px) {
      width: 40%;
    }
    @media screen and (max-width: 500px) {
      width: 70%;
      margin: 1rem 0rem;
    }
  }
}

.level_title {
  color: white;

  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  @media screen and (max-width: 550px) {
    font-size: 23px;
  }
}

.landPic {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  img {
    width: 360px;
    margin: 2rem 0.5rem;
    @media screen and (max-width: 1000px) {
      width: 250px;
    }
    @media screen and (max-width: 650px) {
      width: 200px;
    }
    @media screen and (max-width: 550px) {
      width: 150px;
    }
    @media screen and (max-width: 450px) {
      width: 110px;
    }
  }
}

.id2 {
  color: white;
  font-size: 20px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.mint_sec {
  background: #015a96;
  width: 100%;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.totalScore {
  color: rgb(255, 238, 7);
  font-size: 20px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  margin-bottom: 1rem;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
}

.table {
  width: 75%;
  margin: 1rem 0.5rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 1000px) {
    width: 85%;
  }
  @media screen and (max-width: 750px) {
    width: 95%;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 360px;
  }
}

.indiLand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-family: 'Alfa Slab One', cursive;
  margin-top: 3rem;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.bigtitle {
  color: #ffffff;
  font-size: 30px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  margin-bottom: 2rem;
  @media screen and (max-width: 650px) {
    font-size: 25px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
}

.bigtitle_container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.pipllogo {
  width: 150px !important;
  margin-left: 2rem !important;
  margin-bottom: -0.5rem;
  @media screen and (max-width: 1000px) {
    width: 100px !important;
    margin-left: 1rem !important;
  }
  @media screen and (max-width: 560px) {
    width: 70px !important;
    margin-left: 2rem !important;
  }
  @media screen and (max-width: 425px) {
    width: 60px !important;
  }
  @media screen and (max-width: 380px) {
    width: 60px !important;
  }
}

.wearelogo {
  @media screen and (max-width: 1050px) {
    margin-left: -0.3rem !important;
  }
  @media screen and (max-width: 750px) {
    margin-left: 1rem !important;
  }
  @media screen and (max-width: 560px) {
    margin-left: 2rem !important;
  }
}

.check_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h3 {
    font-size: 22px;
  font-family: 'Alfa Slab One', cursive;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #fff;
  text-align: center;

  @media screen and (max-width: 750px) {
    font-size: 18px;

  }
  @media screen and (max-width: 550px) {
    font-size: 15px;

  }
  }
  .check_sec {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 15px;
    font-family: 'Alfa Slab One', cursive;
    font-weight: 400;
    border: black 3px solid;
    @media screen and (max-width: 750px) {
      width: 50%;
      font-size: 12px;

    }
    
    }
    button {
      width: 70%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 2rem;
      color: white;
      background: #a5a5f7;
      border-radius: 10px;
      font-family: 'Alfa Slab One', cursive;
      font-weight: 400;
      font-size: 17px;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s ease;
      box-shadow: 2px 3px rgb(7, 7, 7);

      @media screen and (max-width: 750px) {
        width: 30%;
        font-size: 12px;
      }
      

    }
  }
  
}