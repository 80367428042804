@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  color: var(--color-text);
  font-family: var(--font-family);
}

.primary-font {
  font-family: 'Alfa Slab One', cursive;
}

.BG {
  background: linear-gradient(
    180deg,
    #3c2e5b 0%,
    #6278b5 9.9%,
    #a7a7f7 52.6%,
    #015998 91.67%,
    rgba(1, 89, 152, 0.7) 99.08%,
    rgba(1, 89, 152, 0) 100%
  );
  z-index: 20;
  .App {
    max-width: 1440px;
    margin: auto;
  }
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__title__blue {
  background: var(--gradient-title-blue);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Dela Gothic One';
}

.gradient__title__gold {
  background: var(--gradient-title-gold);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Dela Gothic One';
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}
