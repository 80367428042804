.organization_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media screen and (max-width: 600px) {
    margin-bottom: -3rem;
  }
}

.title {
  letter-spacing: 2px;
  text-shadow: 1px 1px 1px #030303;
  color: #fff;
  font-size: 35px;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  @media screen and (max-width: 650px) {
    font-size: 30px;
  }
}

.organizations {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.organization {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 600px) {
    width: 50%;
  }

  img {
    animation-duration: 30s;
    @media screen and (max-width: 600px) {
      width: 70%;
    }
  }
  p {
    color: #fff;
    inline-size: 250px;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-shadow: 0.5px 0.5px 0px #000000;
    @media screen and (max-width: 600px) {
      font-size: 70%;
      inline-size: 200px;
    }
    @media screen and (max-width: 400px) {
      font-size: 65%;
      inline-size: 150px;
    }
  }
  button {
    width: 6rem;
    height: 2rem;
    border-radius: 5px;
    text-align: center;
    border: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      width: 4rem;
      font-size: 70%;
      height: 1.5rem;
    }
    &:hover {
      color: var(--hover-color) !important;
      background-color: var(--hover-text) !important;
    }
  }
}
