.Hero_container {
  display: flex;
  width: 80%;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    width: 90%;
  }
}
